<template>
  <div class="container">
    <div class="houses__wrapper screen__wrapper">
      <CardHouse
        v-for="house in houses"
        :key="house.id"
        :obj="house"
        @click.native="info(house)"
      />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import CardHouse from '../Card'
import InfiniteLoading from 'vue-infinite-loading'
import Info from '../Info'

export default {
  name: "ArchiveObjectsHouses",
  components: {
    CardHouse,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    houses() {
      return this.$store.getters['archive/objects/houses/houses']
    },
    hasMoreResults () { 
      return this.houses.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['archive/objects/houses/pagination']?.total
    },
    page() {
      return this.$store.getters['archive/objects/houses/page']
    }
  },
  methods: {
    async loadObj() {
      this.isLoading = true
      const page = this.page
      try {
        await this.$store.dispatch('archive/objects/houses/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    info(info) {
      this.$modal.display(Info, {info}, {name: 'InfoObject'})
    }
  }
};
</script>